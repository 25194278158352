/** @format */

import { Card, Checkbox } from '@shopify/polaris'
import { useEffect, useRef, useState } from 'react'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import moment from 'moment'

const loPassThreshold = 1_200

const LineChart = ({
    eventData,
    title,
    chartData,
    yAxisLabel,
    uiOptions,
    chartComponentRef,
    isLoPass,
    showLegend = false,
    height = 650
}) => {
    const [seriesData, setSeriesData] = useState([])


    useEffect(() => {
        if (!chartData || !chartData.length) {
            return
        }

        if (chartData.length > 100) {
            const pointsPerSeries = 5

            function sampleData(series) {
                const N = series.length;

                if (pointsPerSeries >= N) {
                    return series;
                }

                const indices = _.range(pointsPerSeries).map(i =>
                    Math.round((i * (N - 1)) / (pointsPerSeries - 1))
                );

                const uniqueIndices = _.uniq(indices);

                const sampled = uniqueIndices.map(index => series[index]);

                return sampled;
            }

            for (let i = 0; i < chartData.length; i++) {
                chartData[i].data = sampleData(chartData[i].data)
            }
        }

        setSeriesData(chartData)

    }, [chartData])

    const options = {
        title: {
            text: title
        },
        tooltip: {
            split: false,
            formatter: function (a) {
                //console.log(this)
                const tzOffset = new Date().getTimezoneOffset()
                const tzOffsetSign = tzOffset > 0 ? '-' : '+'
                const tz = 'GMT' + tzOffsetSign + Math.abs(tzOffset) / 60

                let label = `<strong>${this.series.name}</strong><br>X = ${new Date(this.x).toLocaleString() + ' ' + tz
                    }<br>Y = ${this.y}`
                if (this.point.additionalInfo && this.point.additionalInfo.label) {
                    label += '<br>Details: ' + this.point.additionalInfo.label
                }
                return label
            }
        },
        navigator: {
            enabled: true
        },
        scrollbar: {
            enabled: false
        },
        rangeSelector: {
            buttons: [
                {
                    type: 'month',
                    count: 1,
                    text: '1m'
                },
                {
                    type: 'month',
                    count: 3,
                    text: '3m'
                },
                {
                    type: 'month',
                    count: 6,
                    text: '6m'
                },
                {
                    type: 'all',
                    text: 'All'
                }
            ]
        },
        plotOptions: {
            series: {
                animation: false,
                enableMouseTracking: true,
                states: {
                    hover: {
                        enabled: true,
                        brightness: 0.5
                    },
                    inactive: {
                        opacity: 0.2
                    }
                }
            }
        },
        legend: { enabled: showLegend },
        chart: {
            height: height,
            animation: false
        },
        xAxis: {
            type: 'datetime',
            ordinal: false
        },
        yAxis: {
            title: {
                text: yAxisLabel
            },
            min: 0,
            max: isLoPass ? loPassThreshold : null,
            gridLineColor: 'hsl(0, 0%, 90%)'
        },
        series: seriesData,
        accessibility: { enabled: false }
    }

    useEffect(() => {
        if (!eventData.header || !chartComponentRef || !chartComponentRef.current || !chartComponentRef.current.chart) {
            return
        }

        const chart = chartComponentRef.current.chart

        let left = chart.xAxis[0].getExtremes().dataMin

        if (eventData.header.public_sale_start_date_utc) {
            left = new Date(eventData.header.public_sale_start_date_utc).getTime()
        }

        if (eventData.header.presale_start_date_utc) {
            left = new Date(eventData.header.presale_start_date_utc).getTime()
        }

        let right = moment(eventData.header.event_date_utc).toDate().getTime()

        function setExtremes() {
            if (chart.xAxis.length) {
                chart.xAxis[0].setExtremes(left, right)
            }
        }

        // Seems like the chart sometimes needs a bit of time to render before we can the extremes
        setExtremes()
        setTimeout(setExtremes, 1000)
        setTimeout(setExtremes, 3000)
    }, [seriesData, eventData, chartComponentRef])

    return (
        <Card sectioned>
            <div style={{ position: 'relative', padding: '18px 12px' }}>
                <div
                    style={{
                        position: 'relative',
                        marginBottom: 8,
                        opacity: 0.88
                    }}
                    className="wtt-chart-label"
                >
                    {uiOptions}
                </div>
                <HighchartsReact
                    ref={chartComponentRef}
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                />
            </div>
        </Card>
    )
}

export default LineChart
