/** @format */

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import ActiveTrader from './Pages/ActiveTrader'
import AlertsPage from './Pages/TicketMaster/Monitoring/alerts.js'
import AppFrame from './AppFrame'
import { AppProvider } from '@shopify/polaris'
import AutomatePage from './Pages/TicketMaster/AutomatePage'
import BulkBuyer from './Pages/TicketEvolution/BulkBuyer'
import BulkExportsPage from './Pages/TicketMaster/BulkExportsPage'
import CrawlerStatusPage from './Pages/TicketMaster/CrawlerStatusPage'
import CustomLinker from './Pages/TicketMaster/VividLinking/CustomLinker'
import Empty from './Pages/Empty'
import EventLinker from './Pages/TicketMaster/VividLinking/EventLinker'
import EventPage from './Pages/TicketMaster/EventPage'
import EventSearch from './Pages/TicketMaster/EventsSearch'
import InLink from './Pages/InLink'
import LiveEventPage from './Pages/TicketMaster/LiveEventPage'
import LiveInventoryExperimentalPage from './Pages/TicketMaster/LiveInventoryPage/experimental.js'
import LiveInventoryPage from './Pages/TicketMaster/LiveInventoryPage'
import VividLinking from './Pages/TicketMaster/VividLinking'
import enTranslations from '@shopify/polaris/locales/en.json'

/**
 * index >> App >> MainApp >> AppFrame
 * MainApp is responsible for Routing
 */
const MainApp = () => {
    const routes = (
        <Routes>
            <Route exact path="/inLink" element={<InLink />} />s
            <Route
                exact
                path="/ticketMaster/search"
                element={<EventSearch />}
            />
            <Route
                exact
                path="/ticketMaster/search/:automateID"
                element={<EventSearch />}
            />
            {/* 
                There's a lot of redundant routes but:
                - eventTrain always goes to the original page
                - eventPlane always goes to the new page
                - event currently goes to Train but will be switched over to go to Plane
                - eventLive is the previous name for the new page... we should get rid of it
                  + the quicklinks are still using it (global search for "eventLive")
            */}
            <Route
                exact
                path="/ticketMaster/eventTrain/:eventID"
                element={<EventPage />}
            />
            <Route
                exact
                path="/ticketMaster/eventPlane/:eventID"
                element={<LiveEventPage />}
            />
            <Route
                exact
                path="/ticketMaster/event/:eventID"
                element={<EventPage />}
            />
            <Route
                exact
                path="/ticketMaster/eventLive/:eventID"
                element={<LiveEventPage />}
            />
            {/* TODO: rename */}
            <Route
                exact
                path="/ticketMaster/venueLinker"
                element={<VividLinking />}
            />
            <Route
                exact
                path="/ticketMaster/eventLinker"
                element={<EventLinker />}
            />
            <Route
                exact
                path="/ticketMaster/customLinker"
                element={<CustomLinker />}
            />
            <Route
                exact
                path="/ticketMaster/statusPage"
                element={<CrawlerStatusPage />}
            />
            <Route
                exact
                path="/ticketMaster/livePage"
                element={<LiveInventoryPage />}
            />
            <Route
                exact
                path="/ticketMaster/experimentalPage"
                element={<LiveInventoryExperimentalPage />}
            />
            <Route
                exact
                path="/ticketMaster/alertsPage"
                element={<AlertsPage />}
            />
            <Route
                exact
                path="/ticketMaster/bulkExports"
                element={<BulkExportsPage />}
            />
            <Route
                exact
                path="/ticketMaster/automate"
                element={<AutomatePage />}
            />
            <Route
                exact
                path="/ticketEvolution/bulkBuyer"
                element={<BulkBuyer />}
            />
            <Route exact path="/empty" element={<Empty />} />
            <Route exact path="/activeTrader" element={<ActiveTrader />} />
        </Routes>
    )

    return (
        <AppProvider i18n={enTranslations}>
            <BrowserRouter>
                <AppFrame>{routes}</AppFrame>
            </BrowserRouter>
        </AppProvider>
    )
}

export default MainApp
