import _, { set } from "lodash";
import { Card, Page } from "@shopify/polaris";
import { useEffect, useState, useRef } from "react";
import { apiGet } from "../../../Api";
import { XAxis, YAxis, BarChart, Bar, Tooltip } from "recharts";
import { PropagateLoader } from "react-spinners";
import moment from "moment";

const LiveTmStatusSection = () => {
  const [workerStatus, setWorkerStatus] = useState();
  const [latestRecords, setLatestRecords] = useState([]);
  const [rawDumpData, setRawDumpData] = useState({});
  const [chart1Data, setchart1Data] = useState({});

  const [socketData, setSocketData] = useState([]);
  const [socketMessages, setSocketMessages] = useState([]);

  const [chart2Data, setchart2Data] = useState([]);
  const [last1MinUpdates, setLast1MinUpdates] = useState([]);
  const [realtimeHottest, setRealtimeHottest] = useState([]);

  const latestActivityCount = 20;

  const connectWs = () => {
    const protocol = window.location.protocol.includes("https") ? "wss" : "ws";

    const wsUrl =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
        ? `${protocol}://${window.location.hostname}:7001`
        : `${protocol}://${window.location.hostname}:${window.location.port}`;
    console.log("connecting to ws url", wsUrl);
    ws.current = new WebSocket(wsUrl);

    ws.current.onclose = function () {
      console.log("ws on close");
      if (dontRestartWs.current) return;
      console.log("ws reconnecting");
      connectWs();
    };

    ws.current.onmessage = function (event) {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case "heartbeat":
          //console.log("heartbeat", data);
          break;
        case "update":
          //console.log("update", data);
          let items = data.content;
          //console.log('items', items)
          setSocketMessages((smz) => {
            let now = Math.floor(new Date().valueOf() / 1000);
            let newTimestamps = items.map((x) => now);
            return [...smz, ...newTimestamps].filter((x) => now - x < 120);
          });

          setSocketData((its) => {
            let newItems = [...its, ...items];
            return _.takeRight(newItems, latestActivityCount);
          });

          setLast1MinUpdates((its) => {
            let now = moment();
            let combined = [...its, ...items];
            let filtered = combined.filter(
              (c) => moment(c.fetched_at) > now.subtract(1, "minute")
            );
            //console.log("filtered", filtered);
            return filtered;
          });

          break;
        default:
          console.log("unknown", data);
      }
    };

    ws.current.onopen = () => {
      console.log("ws on open. subscribing");
      ws.current.send(JSON.stringify({ type: "subscribe" }));
    };

    return () => {
      console.log("closing ws effect");
      dontRestartWs.current = true;
      ws.current.close();
    };
  };

  const ws = useRef(null);
  const dontRestartWs = useRef(false);

  useEffect(() => connectWs(), []);

  useEffect(
    (smz) => {
      setchart2Data(() => {
        let x = _.chain(socketMessages)
          .countBy()
          .mapValues((val, key) => val)
          .mapKeys((val, key) => moment(1000 * key).format("HH:mm:ss"))
          .value();

        let now = Math.floor(new Date().valueOf() / 1000);
        let y = new Array(120).fill(0).map((v, i) => {
          let ts = moment(1000 * (now - (119 - i))).format("HH:mm:ss");
          let value = x[ts] | 0;
          return { when: ts, count: value };
        });

        return [...y];
      });
    },
    [socketMessages]
  );

  useEffect(
    (l1m) => {
      //console.log("updating realtime hottest", last1MinUpdates);
      setRealtimeHottest(() => {
        let x = _.chain(last1MinUpdates)
          .groupBy((x) => x.event_id)
          .mapValues((val, key) => _.sumBy(val, (v) => Math.abs(v.change)))
          .map((v, k) => ({ event_id: k, movement: v }))
          .orderBy(["movement"], ["desc"])
          .value();
        return x;
      });
    },
    [last1MinUpdates]
  );

  const rawDumpers = {
    "Demo events with the most seat movement in the past 5 minutes": {
      sql: `
        select tli.event_id, tme.tm_id, sum(change) as movement, tme.event_url_link
        from tm_live_inventory tli
        join tm_event tme on tli.event_id = tme.id
        where fetched_at > now() - interval 5 minute 
        group by tli.event_id, tme.tm_id, tme.event_url_link
        order by abs(movement) desc 
        limit 10;
      `,
    },
    "Demo events with the most seat movement in the past 30 minutes": {
      sql: `
        select tli.event_id, tme.tm_id, sum(change) as movement, tme.event_url_link
        from tm_live_inventory tli
        join tm_event tme on tli.event_id = tme.id
        where fetched_at > now() - interval 30 minute 
        group by tli.event_id, tme.tm_id, tme.event_url_link
        order by abs(movement) desc 
        limit 10;
      `,
    },
    "Demo events with the most seat movement in the past 1 hour": {
      sql: `
        select tli.event_id, tme.tm_id, sum(change) as movement, tme.event_url_link
        from tm_live_inventory tli
        join tm_event tme on tli.event_id = tme.id
        where fetched_at > now() - interval 1 hour 
        group by tli.event_id, tme.tm_id, tme.event_url_link
        order by abs(movement) desc 
        limit 10;
      `,
    },
    "Demo events with the most seat movement in the past 24 hours": {
      sql: `
        select tli.event_id, tme.tm_id, sum(change) as movement, tme.event_url_link
        from tm_live_inventory tli
        join tm_event tme on tli.event_id = tme.id
        where fetched_at > now() - interval 24 hour 
        group by tli.event_id, tme.tm_id, tme.event_url_link
        order by abs(movement) desc 
        limit 10;
      `,
    },
    "Total records in the past 24 hours": {
      sql: "select count(*) from tm_live_inventory where fetched_at > now() - interval 24 hour",
    },
    "Total live inventory records in db": {
      sql: "select count(*) from tm_live_inventory",
    },
  };

  useEffect(() => {
    let intervalHandle = null;
    let busy = false;
    async function fetchData() {
      async function doUpdate() {
        if (busy) return;
        busy = true;
        let ans = await apiGet("/tmLive/query", {
          sql: `
          
          select * from
          (
          select
          floor(UNIX_TIMESTAMP()/60)  - floor(UNIX_TIMESTAMP(fetched_at)/60) as lookback
          ,substr(convert_tz(time_slice(fetched_at, interval 1 minute), 'GMT', 'America/New_York'), 12) as dt
          ,sum(change) as movement
          from tm_live_inventory 
          group by lookback, dt
          order by lookback asc
          limit 120
          ) t1
          order by lookback desc

        `,
        });

        setchart1Data(ans);
        busy = false;
      }
      doUpdate();
      intervalHandle = setInterval(doUpdate, 10000);
    }
    fetchData().catch(console.error);
    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  useEffect(() => {
    let intervalHandle = null;
    let busy = false;
    async function fetchData() {
      async function doUpdate() {
        if (busy) return;
        busy = true;

        let statusData = await apiGet("/tmLive/workerStatus");
        setWorkerStatus(statusData);

        let latestRecordsData = await apiGet("/tmLive/latestRecords", {
          count: latestActivityCount,
        });

        setLatestRecords(latestRecordsData);
        busy = false;
      }

      doUpdate();
      intervalHandle = setInterval(doUpdate, 5000);
    }

    fetchData().catch(console.error);

    return () => {
      console.log("called cleanup");
      clearInterval(intervalHandle);
    };
  }, []);

  useEffect(() => {
    let intervalHandle = null;
    let busy = false;
    async function fetchData() {
      async function doUpdate() {
        if (busy) return;
        busy = true;

        for (let [key, dumper] of Object.entries(rawDumpers)) {
          console.log(key);
          console.log(dumper.sql);
          let ans = await apiGet("/tmLive/query", { sql: dumper.sql });
          rawDumpData[key] = ans;
        }

        setRawDumpData(rawDumpData);
        busy = false;
      }

      doUpdate();
      intervalHandle = setInterval(doUpdate, 10000);
    }

    fetchData().catch(console.error);

    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  function padEnd(x, n) {
    const yy = x.toString();
    return yy.padEnd(n);
  }

  return (
    <>
      <h1 style={{ margin: "1em 0em" }}>
        Overall seats moved by the minute in the last 2 hours
      </h1>
      <BarChart
        width={1100}
        height={200}
        barCategoryGap={"5%"}
        data={chart1Data}
      >
        <XAxis dataKey="dt" interval={18} /*angle={-15} textAnchor="end"*/ />
        <Bar
          isAnimationActive={false}
          dataKey="movement"
          fill="hsl(220, 45%, 65%)"
        />
        <YAxis />
        <Tooltip cursor={{ fill: "hsla(0, 0%, 50%, 0.08)" }} />
      </BarChart>

      <div>&nbsp;</div>

      <Card title="Live Instances Status" sectioned>
        <p
          style={{
            marginBottom: "0.5em",
          }}
        >
          Number of monitor instances currently online:{" "}
          {workerStatus && workerStatus.length}
        </p>
        <>
          {workerStatus &&
            workerStatus.length &&
            workerStatus.map((w, i) => (
              <div key={w.name} className="mono">
                {w.name}
              </div>
            ))}
        </>
      </Card>

      { false && 
      <>
      <Card sectioned title="Raw Dump of Latest Activity From Database">
        <>
          <div>
            {latestRecords &&
              latestRecords.map((r, ix) => (
                <div key={"rawdump" + ix} className="mono">
                  {padEnd(r.event_id, 16)} section {padEnd(r.section_id, 7)}{" "}
                  {(
                    (r.change === 0 ? " " : r.change > 0 ? "+" : "-") +
                    Math.abs(r.change)
                  ).padStart(4)}
                  {"  "}
                  to {r.count.toString().padStart(5)} at
                  {"  "}
                  {r.fetched_at}
                </div>
              ))}
          </div>
        </>
      </Card>

      <Card
        sectioned
        title="Raw Dump of Latest Activity from Live Aggregated Sockets"
      >
        <>
          <div className="mono">
            {socketData &&
              socketData.map((r) => (
                <div key={JSON.stringify(r) + Math.random()}>
                  {padEnd(r.event_id, 16)} section {padEnd(r.section_id, 7)}{" "}
                  {(
                    (r.change === 0 ? " " : r.change > 0 ? "+" : "-") +
                    Math.abs(r.change)
                  ).padStart(4)}
                  {"  "}
                  to {r.count.toString().padStart(5)} at
                  {"  "}
                  {r.fetched_at}
                </div>
              ))}
          </div>
        </>
      </Card>

      <div>&nbsp;</div>
      <h1 style={{ margin: "1em 0em" }}>
        Websocket messages per second in the last 2 minutes
      </h1>
      <BarChart
        width={1100}
        height={200}
        barCategoryGap={"5%"}
        data={chart2Data}
      >
        <XAxis minTickGap={50} dataKey="when" />
        <Bar
          isAnimationActive={false}
          dataKey="count"
          fill="hsl(220, 45%, 65%)"
        />
        <YAxis />
        <Tooltip cursor={{ fill: "hsla(0, 0%, 50%, 0.08)" }} />
      </BarChart>

      <div>&nbsp;</div>
      <Card
        title="🔥 &nbsp;&nbsp;Real-time top 10 hottest events of the last 60 seconds"
        sectioned
      >
        <div className="mono">
          {_.take(realtimeHottest, 9).map((x, i) => (
            <div key={x.event_id}>
              # {(i+1).toString().padEnd(5)}{x.event_id.padEnd(18) /* TODO handle null */}{" "}
              {x.movement.toString().padStart(4)}
            </div>
          ))}
        </div>
      </Card>
      </>
    }

      <Card title="Demo Raw Query Dumps" sectioned>
        <div>
          {Object.entries(rawDumpData).map(([name, results]) => (
            <div key={name} style={{ marginTop: "1em" }}>
              <div style={{ fontStyle: "italic", fontWeight: 700 }}>{name}</div>
              <div
                style={{ opacity: 0.6, fontSize: "10px", lineHeight: 1.5 }}
                className="mono"
              >
                {rawDumpers[name].sql}
              </div>

              <table
                className="tabledump"
                style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
              >
                <thead>
                  <tr key={Math.random()}>
                    {results.length === 0
                      ? ""
                      : Object.keys(results[0]).map((r, i) => (
                          <th key={JSON.stringify(r)}>{r}</th>
                        ))}
                  </tr>
                </thead>

                <tbody>
                  {results.map((r, i) => (
                    <tr key={i}>
                      {Object.values(r).map((c, j) => (
                        <td key={JSON.stringify(c)}>{c}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div>&nbsp;</div>
            </div>
          ))}
        </div>

        {Object.keys(rawDumpData).length !== Object.keys(rawDumpers).length ? (
          <div style={{ textAlign: "center" }}>
            <span style={{ display: "inline-block" }}>
              <PropagateLoader
                style={{ marginLeft: "0px" }}
                color="hsl(220, 0%, 85%)"
                speedMultiplier={0.6}
                size={7}
              />
              <h1 style={{ marginTop: "18px", opacity: "0.3" }}>
                &nbsp;Loading
              </h1>
            </span>
          </div>
        ) : (
          <span></span>
        )}
      </Card>
    </>
  );
};

const LiveInventoryExperimentalPage = () => {
  return (
    <Page
      title="Live Inventory Experimental Page"
      subtitle="Monitor real-time activity on TicketMaster"
      fullWidth
    >
      <LiveTmStatusSection />
    </Page>
  );
};

export default LiveInventoryExperimentalPage;
